//core
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import {
  Box,
  RadioGroup,
} from "@mui/material";
import Button from "../../../UI/Buttons/Button";

//styles
import { useStyles } from "./styles";

//components
import SavedFilterItem from "./components/SavedFilterItem";
import { IMyFilterItem } from "../../../admin/Reports/SummaryReportV2/components/DrawerFilter";
import { ListPayloadType } from "../../../../store/common/storage";

interface IMyFiltersProps {
  fieldsLabelPrefix?: string;
  initialFilter: object;
  data: any[],
  type: string;
  target: string;
  exceptions: string[];
  exceptionsIds: string[];
  getMyFilters: (payload: ListPayloadType) => void;
  getPayloadFilters: () => ListPayloadType;
  selectedMyFilterId: string;
  setSelectedMyFilterId: React.Dispatch<React.SetStateAction<string>>;
  selectedFilterId: string;
  setSelectedFilterId: React.Dispatch<React.SetStateAction<string>>;
  setUpdateFilter: (data: any) => void;
  setToggleDrawer: (data: boolean) => void;
  setGenerateReport?: React.Dispatch<React.SetStateAction<boolean>>;
  EditComponent: any;
  defaultFilter: object;
  fieldsPrefix? : any;
}

const MyFilters: React.FC<IMyFiltersProps> = ({
  initialFilter,
  data,
  setToggleDrawer,
  setUpdateFilter,
  setGenerateReport = null,
  type,
  target,
  exceptions,
  exceptionsIds,
  getMyFilters,
  getPayloadFilters,
  selectedMyFilterId,
  setSelectedMyFilterId,
  selectedFilterId,
  setSelectedFilterId,
  fieldsLabelPrefix = 'admin.reports.filter.',
  EditComponent,
  defaultFilter,
  fieldsPrefix,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const [selectedMyFilter, setSelectedMyFilter] = useState<any | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMyFilterId((event.target as HTMLInputElement).value);
  };

  const handleApplyFilter = useCallback(() => {
    const newFilterState = {
      ...initialFilter,
      ...selectedMyFilter.data,
    };

    setUpdateFilter(newFilterState);
    setToggleDrawer(false);
    setSelectedFilterId(`${selectedMyFilter.id}`);
    if (typeof setGenerateReport === "function") {
      setTimeout(() => {
        setGenerateReport((prevState) => {
          return !prevState
        });
      }, 120);
    }
  }, [selectedMyFilter, initialFilter]);

  return (
    <>
      <Box className={styles.wrapper}>
        <Scrollbars
          className={styles.customScroll}
          style={{ overflow: "hidden" }}
          hideTracksWhenNotNeeded={true}
          renderView={props => <div {...props} className="view"/>}
          renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}
          renderTrackVertical={props => <div {...props} className="track-vertical"/>}
        >
          {!!data.length && (
            <RadioGroup
              name="my-filters-group"
              value={selectedMyFilterId}
              onChange={handleChange}
            >
              {data.map((item) => (
                <SavedFilterItem
                  key={`my-filter-${item.id}`}
                  data={item}
                  selected={`${item.id}` === `${selectedMyFilterId}`}
                  type={type}
                  target={target}
                  getMyFilters={getMyFilters}
                  getPayloadFilters={getPayloadFilters}
                  setSelectedMyFilter={setSelectedMyFilter}
                  exceptions={exceptions}
                  exceptionsIds={exceptionsIds}
                  fieldsLabelPrefix={fieldsLabelPrefix}
                  EditComponent={EditComponent}
                  defaultFilter={defaultFilter}
                  setSelectedMyFilterId={setSelectedMyFilterId}
                  setSelectedFilterId={setSelectedFilterId}
                  fieldsPrefix={fieldsPrefix}
                />
              ))}
            </RadioGroup>
          )}
        </Scrollbars>
      </Box>
      <Box className={styles.actions}>
        <Box className={styles.actionsWrapper}>
          <Button
            gradient
            className={styles.button}
            fullWidth
            disableElevation
            type="submit"
            variant="contained"
            onClick={handleApplyFilter}
            disabled={!selectedMyFilterId || !selectedMyFilter || (`${selectedFilterId}` === `${selectedMyFilter?.id}`)}
          >
            {t("common.buttons.apply")}
          </Button>
          <Button
            className={styles.button}
            color="secondary"
            disableElevation
            type="button"
            variant="outlined"
            onClick={() => setToggleDrawer(false)}
          >
            {t("common.buttons.cancel")}
          </Button>
        </Box>
      </Box>
    </>

  );
};

export default MyFilters;
