//core
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import arePropsEqual from "react-fast-compare";
import format from "date-fns/format";
import { endOfDay, startOfDay } from "date-fns";
import {
  Box,
  IconButton,
  Badge,
  Tooltip,
  Collapse,
  useMediaQuery,
} from '@mui/material';
import Button from "../../../../../UI/Buttons/Button";

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

//helpers
import { countValuesInObj, countValuesInObjWithExceptions } from "../../../../../../helpers/countValuesInObj";

//styles
import { useStyles } from "./styles";

//selectors
import {
  selectListData,
  selectOptionsData,
  selectListDataColumnsSort,
  selectDealsData,
  selectPlatformsData,
} from "../../../../../../store/admin/reports/summary_report_v2/selectors";

//hooks
import { useSummaryReport } from "../../../../../../store/admin/reports/summary_report_v2/useSummaryReport";

//context
import { GlobalContext } from "../../../../../../context/GlobalContext";

//components
import { ISummaryReportFilterDataType, OrderType } from "../../types";
import MuiDrawerWrapper from "../../../../../UI/MuiDrawerWrapper";
import VisibleFilter from "./components/VisibleFilter";
import SortMenu from "../../../../../common/SortMenu";
//import Filter from "./components/Filter";
import SelectedFiltersWrapper from "./components/SelectedFiltersWrapper";
import PermissionsWrapper from "../../../../../common/PermissionsWrapper";
import FilterTabs from "./components/FilterTabs";
import { initialSummaryReportFilterData as initialFilterData } from "../DesktopReport";
import TableSettings from "../../../../../common/FiltersComponents/TableSettings";
import { TableColumnsItem } from "../../../../../common/FiltersComponents/TableSettings/types";
import {reportStorageProps} from "../../constants";

export interface IMyFilterItem {
  [key: string]: any;
}

interface IDrawerFilterProps {
  initialFilter: ISummaryReportFilterDataType;
  setUpdateFilter: React.Dispatch<React.SetStateAction<ISummaryReportFilterDataType>>;
  children?: React.ReactNode | null;
  order: OrderType;
  setOrder: (order: OrderType) => void;
  showOrderOnlyMobile?: boolean;
  onDownload: () => void;
  onGenerateReport: () => void;
  setGenerateReport: React.Dispatch<React.SetStateAction<boolean>>;
  columns: TableColumnsItem[];
  defaultColumns: TableColumnsItem[];
  setColumns: React.Dispatch<React.SetStateAction<TableColumnsItem[]>>;
  selectedFilterId: string;
  setSelectedFilterId: React.Dispatch<React.SetStateAction<string>>;
}

const DrawerFilter: React.FC<IDrawerFilterProps> = ({
  initialFilter,
  setUpdateFilter,
  children = null,
  order,
  setOrder,
  onDownload,
  onGenerateReport,
  setGenerateReport,
  columns,
  setColumns,
  defaultColumns,
  setSelectedFilterId,
  selectedFilterId,
}): JSX.Element => {
  const { t } = useTranslation();
  const styles = useStyles();

  const mobile = useMediaQuery('(max-width:767px)');

  const filterExceptions = mobile
    ? ['period', 'sent_from', 'sent_to']
    : ['period', 'sent_from', 'sent_to', 'group_by'];

  const context = useContext(GlobalContext);

  const { getOptions, getDeals, getPlatforms } = useSummaryReport();

  const optionsData = useSelector(selectOptionsData);
  const dataReport = useSelector(selectListData);
  const sortingColumns = useSelector(selectListDataColumnsSort);

  const dealsData = useSelector(selectDealsData);
  const platformsData = useSelector(selectPlatformsData);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [showVisibleFilter, setShowVisibleFilter] = useState(true);
  const [activeField, setActiveField] = useState('');

  useEffect(() => {
    if (!optionsData) {
      getOptions();
    }
  }, [optionsData]);

  useEffect(() => {
    if (!dealsData) {
      getDeals();
    }
  }, [dealsData]);

  useEffect(() => {
    if (!platformsData) {
      getPlatforms({
        page: 1,
        search: null,
        per_page: 100,
      });
    }
  }, [platformsData]);

  useEffect(() => {
    if (mobile && !!dataReport) {
      setShowVisibleFilter(false)
    }
  }, [dataReport]);

  useEffect(() => {
    if (!mobile) {
      setShowVisibleFilter(true);
    } else {
      if (!!dataReport) {
        setShowVisibleFilter(false)
      }
    }
  }, [mobile]);

  const handleClearFilters = useCallback(() => {
    setUpdateFilter({
      ...initialFilterData,
      sent_from: format(startOfDay(new Date(initialFilterData.sent_from)), "yyyy-MM-dd HH:mm:ss"),
      sent_to: format(endOfDay(new Date(initialFilterData.sent_to)), "yyyy-MM-dd HH:mm:ss"),
    });
    context.setAdmSummaryReportFilter({
      ...initialFilterData,
      sent_from: format(startOfDay(new Date(initialFilterData.sent_from)), "yyyy-MM-dd HH:mm:ss"),
      sent_to: format(endOfDay(new Date(initialFilterData.sent_to)), "yyyy-MM-dd HH:mm:ss"),
    })

    //clear selectedFilterId
    setSelectedFilterId('');

    setTimeout(() => {
      setGenerateReport((prevState) => {
        return !prevState
      });
    }, 100)
  }, [setUpdateFilter]);

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleSetActiveField = (field: string) => {
    setActiveField(field);
    setOpenDrawer(true);
  };

  const handleToggleCollapse = () => {
    setOpenCollapse(prevState => !prevState);
  };

  const onDeleteOption = useCallback((id: string | number, fieldName: string) => {
    setUpdateFilter( (prevState) => {
      // @ts-ignore
      const newFieldData = prevState[fieldName].filter((item: any) => item.id !== id);

      context.setAdmSummaryReportFilter({
        ...context.admSummaryReportFilter,
        // @ts-ignore
        [fieldName]: context.admSummaryReportFilter[fieldName].filter((item: any) => item.id !== id),
      });

      //clear selectedFilterId
      setSelectedFilterId('');

      return {
        ...prevState,
        [fieldName]: newFieldData,
      }
    });

    setTimeout(() => {
      setGenerateReport((prevState) => {
        return !prevState
      });
    }, 100)
  }, [setUpdateFilter]);

  const collapseButton = (<>
    <Button
      type="button"
      variant="text"
      color="secondary"
      size="small"
      onClick={handleToggleCollapse}
      startIcon={openCollapse ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    >
      {openCollapse
        ? t("common.components.filters.hide")
        : t("common.components.filters.show")}
    </Button>
  </>);

  return (
    <>
      <Box className={styles.block}>
        {!mobile && (
          <Box className={styles.first}>
            {!mobile && (<>
              { !!children && children }
            </>)}
          </Box>
        )}

        <Box className={styles.second}>
          <Box className={styles.actions}>
            {/*reset filter*/}
            <IconButton
              className={styles.iconButton}
              size={mobile ? 'small' : 'medium'}
              onClick={handleClearFilters}
              disabled={arePropsEqual({
                ...initialFilterData,
                sent_from: format(new Date(initialFilterData.sent_from), "yyyy-MM-dd HH:mm:ss"),
                sent_to: format(new Date(initialFilterData.sent_to), "yyyy-MM-dd HH:mm:ss"),
              }, {
                ...initialFilter,
                sent_from: format(new Date(initialFilter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                sent_to: format(new Date(initialFilter.sent_to), "yyyy-MM-dd HH:mm:ss"),
              })}
            >
              <Tooltip title={t("common.buttons.reset_filters")} placement="bottom">
                <FilterAltOffIcon
                  color={arePropsEqual({
                    ...initialFilterData,
                    sent_from: format(new Date(initialFilterData.sent_from), "yyyy-MM-dd HH:mm:ss"),
                    sent_to: format(new Date(initialFilterData.sent_to), "yyyy-MM-dd HH:mm:ss"),
                  }, {
                    ...initialFilter,
                    sent_from: format(new Date(initialFilter.sent_from), "yyyy-MM-dd HH:mm:ss"),
                    sent_to: format(new Date(initialFilter.sent_to), "yyyy-MM-dd HH:mm:ss"),
                  }) ? 'inherit' : 'primary'}
                />
              </Tooltip>
            </IconButton>

            {/*open filter*/}
            <IconButton
              className={styles.iconButton}
              size={mobile ? 'small' : 'medium'}
              onClick={handleOpenDrawer}
            >
              <Tooltip title={t("common.components.filters.title")} placement="bottom">
                <Badge
                  className={styles.badge}
                  badgeContent={countValuesInObjWithExceptions(initialFilter, filterExceptions)}
                  color="secondary"
                >
                  <FilterAltIcon
                    color={!!countValuesInObjWithExceptions(initialFilter, filterExceptions) ? 'primary' : 'inherit'}
                  />
                </Badge>
              </Tooltip>
            </IconButton>

            {/*sort menu*/}
            {!!sortingColumns && mobile && (
              <SortMenu
                color="default"
                columns={sortingColumns}
                order={order}
                setOrder={setOrder}
                noMargin={true}
                prefix="columns_for_display.table.reports.columns."
              />
            )}

            {/* Report columns settings */}
            <PermissionsWrapper permissions={["api3.admin.playercommissionreportcontroller.sort_columns"]}>
              {!!columns.length && (
                <TableSettings
                  columns={columns}
                  defaultColumns={defaultColumns}
                  setColumns={setColumns}
                  prefix="columns_for_display.table.reports.columns."
                  storageType={reportStorageProps.type}
                  storageTarget={reportStorageProps.tableSettingsTarget}
                  setGenerateReport={setGenerateReport}
                />
              )}
            </PermissionsWrapper>

            {/*download*/}
            <PermissionsWrapper permissions={["api3.admin.playercommissionreportcontroller.download"]}>
              <IconButton
                className={styles.iconButton}
                size={mobile ? 'small' : 'medium'}
                onClick={onDownload}
              >
                <Tooltip title={t("common.buttons.download_report")} placement="bottom">
                  <DownloadIcon />
                </Tooltip>
              </IconButton>
            </PermissionsWrapper>
          </Box>
        </Box>
      </Box>

      {!mobile && (
        <Box className={styles.buttonWrapper}>
          <Button
            gradient
            sx={{ width: 'max-content' }}
            disableElevation
            type="submit"
            variant="contained"
            size={mobile ? 'small' : 'medium'}
            onClick={onGenerateReport}
          >
            { t("common.buttons.generate_report") }
          </Button>

          {!!countValuesInObj(initialFilter) && collapseButton}
        </Box>
      )}

      {!mobile ? (
        <Collapse in={openCollapse} collapsedSize={0}>
          <Collapse in={showVisibleFilter} collapsedSize={0} timeout={100}>
            <VisibleFilter
              filter={initialFilter}
              setUpdateFilter={setUpdateFilter}
            />
          </Collapse>

          {!!countValuesInObjWithExceptions(initialFilter, filterExceptions) && (
            <SelectedFiltersWrapper
              initialFilter={initialFilter}
              setActiveField={handleSetActiveField}
              onDeleteOption={onDeleteOption}
            />
          )}
        </Collapse>
      ) : (
        <Collapse in={showVisibleFilter} collapsedSize={0} timeout={100}>
          <VisibleFilter
            filter={initialFilter}
            setUpdateFilter={setUpdateFilter}
          />
        </Collapse>
      )}

      <MuiDrawerWrapper
        title={t("common.components.filters.title")}
        open={openDrawer}
        toggleDrawerState={setOpenDrawer}
      >
        <FilterTabs
          activeField={activeField}
          initialFilter={initialFilter}
          defaultFilter={initialFilterData}
          setUpdateFilter={setUpdateFilter}
          setToggleDrawer={setOpenDrawer}
          setGenerateReport={setGenerateReport}
          selectedFilterId={selectedFilterId}
          setSelectedFilterId={setSelectedFilterId}
        />
      </MuiDrawerWrapper>
    </>
  );
};

export default DrawerFilter;
